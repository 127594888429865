<template>
    <div>
        <page-title icon="fas fa-envelope-open-text" heading="SMTP" subheading="Manage company emails"
                    :options="[
                    {title:'Insert an SMTP mail',class:'btn-success',icon:'fas fa-plus',event:()=>{editMail(0)}},
                    {class:'btn-dark invisible',icon:'fas fa-sync-alt',event:()=>{load({})}},
                    ]"
        />
        <div id="branches" class="overflow-hidden w-100">
            <div class="w-100">

            </div>
            <div class="row m-0 p-0">
                <!--MAIL-->
                <div class="col-12 ml-0 mr-0 p-0 mb-2">
                    <md-card>
                        <div class="card-header" style="overflow: hidden">
                            <span class="card-title m-0">{{msg("Mail")}}</span>

                        </div>
                        <!--Mail Card Content-->
                        <md-card-content>
                            <table class="table b-table table-hover" v-bind:class="{'table-striped':settings.mail.status.loaded}">
                                <thead>
                                <tr>
                                    <th @click="sort('id')" aria-sort="none"><a>{{msg("Id")}}</a></th>
                                    <th @click="sort('alias')" aria-sort="none">{{msg("Alias")}}</th>
                                    <th @click="sort('sender')" aria-sort="none">{{msg("Sender")}}</th>
                                    <th @click="sort('host')" aria-sort="none">{{msg("Host")}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <!--Table-->
                                <template v-if="settings.mail.status.loaded === true">
                                    <tr v-for="m in settings.mail.mails" v-bind:key="m.id" @click="editMail(m.id)">
                                        <td>
                                            {{m.id}}
                                        </td>
                                        <td>
                                            {{m.alias}}
                                        </td>
                                        <td>
                                            {{m.sender}}
                                        </td>
                                        <td>
                                            {{m.host}}
                                        </td>
                                    </tr>
                                </template>
                                <template v-else-if="settings.mail.status.loaded !== true && settings.mail.status.error !== ''">
                                    <tr>
                                        <td colspan="3">
                                            <b-alert show variant="danger">
                                                <i class="fas fa-exclamation-triangle mr-3"></i>
                                                <strong>{{settings.mail.status.error}}</strong>
                                            </b-alert>
                                        </td>
                                    </tr>
                                </template>
                                <!--Loading-->
                                <template v-else>
                                    <tr class="table-loading">
                                        <td colspan="9">
                                            <p>
                                                <b-spinner label="Spinning"/>
                                                {{msg("loading")}}
                                            </p>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </md-card-content>
                    </md-card>
                </div>
            </div>
        </div>


        <!--MAIL MODAL-->
        <b-modal no-fade v-model="dialogs.mail.open" size="xl" :hide-footer="true" :hide-header="true" no-close-on-backdrop centered>
            <template v-if="dialogs.mail.open">
                <smtpSettingsPanel :id="dialogs.mail.selected" @submitted="onMailSubmit"></smtpSettingsPanel>
                <md-dialog-actions style="justify-content:center">
                    <b-button variant="outline-primary" class="btn-dialog mr-2" @click="dialogs.mail.open=false">
                        {{msg("Close")}}
                    </b-button>
                    <b-button variant="outline-primary" class="btn-dialog" @click="submitMail">{{msg("Save")}}
                    </b-button>
                </md-dialog-actions>
            </template>
        </b-modal>

    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
    import smtpSettingsPanel from "../../components/panels/smtpSettingsPanel";

     import PageTitle from "@/@core/components/page-title/PageTitle";


    export default {
        name: "businessUnitsSettings",
        components: {smtpSettingsPanel, PageTitle},
        data() {
            return {

                //Dialogs to show
                dialogs: {
                    mail: {open: false, selected: 0},
                    kyc: {open: false, selected: 0},
                }
            }
        },
        computed: {
            ...mapGetters('translator', ['msg']),
            ...mapState({
                settings: function (store) {
                    return store.BranchSettings;
                },
                data: function (store) {
                    return store.data
                },
            }),
        },
        created() {
            this.load();
        },
        methods: {
            ...mapActions('BranchSettings', ['loadData']),
            load() {
                let $this = this;
                this.loadData().then(() => {
                    $this.$forceUpdate();
                }, (error) => $this.error = error);
            },
            editMail(id) {
                this.dialogs.mail.selected = id;
                this.dialogs.mail.open = true;
            },
            editKyc(id) {
                this.dialogs.kyc.selected = id;
                this.dialogs.kyc.open = true;
            },
            submitMail() {
                this.$root.$emit('crm::submitSmtpSettings')
            },
            onMailSubmit(ans) {
                if (ans.status === true && ans.id != null && !isNaN(ans.id))
                    this.dialogs.mail.selected = ans.id;
                console.log(ans)
            },
            submitKyc() {
                this.$root.$emit('crm::submitKycSettings')

            },
        }
    }
</script>

<style>


</style>
