<template>
    <div class="smtpSettingsPanel">
        <b-tabs class="tabs-title">
            <b-tab title="SMTP settings" active>
                <perfect-scrollbar style="max-height: 550px">
                    <div style="padding: 20px" class="md-scrollbar">
                        <div v-if="loaded">
                            <form id="mailForm" ref="mailForm" @submit.prevent="submit">
                                <div class="md-list md-dense">
                                    <md-subheader>{{msg('Host')}}</md-subheader>
                                    <div class="row ">
                                        <div class="col-1 md-layout-item md-size-4">
                                            <md-list>
                                                <md-list-item>
                                                    <md-icon class="md-primary" style="padding: 0"><i class="fas fa-at"></i></md-icon>
                                                </md-list-item>
                                            </md-list>
                                        </div>
                                        <div class="row col-11">
                                            <div class="md-layout-item mr-2">
                                                <md-field :class="{ 'md-invalid': validate('alias')}">
                                                    <label>{{msg('Alias')}}</label>
                                                    <md-input v-on:blur="$v.$touch()" v-model="mail.alias"/>
                                                    <span class="md-error" v-if="mail.alias!= null &&!$v.mail.alias.required">Alias is required</span>
                                                </md-field>
                                            </div>
                                            <div class="md-layout-item mr-2">
                                                <md-field :class="{ 'md-invalid': validate('host')}">
                                                    <label>{{msg('Host')}}</label>
                                                    <md-input v-on:blur="$v.$touch()" v-model="mail.host"/>
                                                    <span class="md-error" v-if="mail.host!= null &&!$v.mail.host.required">Host is required</span>
                                                </md-field>

                                            </div>
                                            <div class="md-layout-item">
                                                <md-field :class="{ 'md-invalid': validate('sender')}">
                                                    <label>{{msg('Sender')}}</label>
                                                    <md-input v-on:blur="$v.$touch()" v-model="mail.sender"/>
                                                    <span class="md-error" v-if="mail.sender!= null &&!$v.mail.sender.required">Alias is required</span>
                                                </md-field>
                                            </div>
                                        </div>
                                    </div>
                                    <md-divider></md-divider>

                                    <md-subheader>{{msg('Credentials')}}</md-subheader>
                                    <div class="row ">
                                        <div class="col-1 md-layout-item md-size-4">
                                            <md-list>
                                                <md-list-item>
                                                    <md-icon class="md-primary" style="padding: 0"><i class="fas fa-key"></i></md-icon>
                                                </md-list-item>
                                            </md-list>
                                        </div>
                                        <div class="row col-11">
                                            <div class="md-layout-item mr-2">
                                                <md-field :class="{ 'md-invalid': validate('username')}">
                                                    <label>{{msg('Username')}}</label>
                                                    <md-input v-on:blur="$v.$touch()" v-model="mail.username"/>
                                                    <span class="md-error" v-if="mail.username!= null &&!$v.mail.username.required">Username is required</span>
                                                </md-field>
                                            </div>
                                            <div class="md-layout-item">
                                                <md-field :class="{ 'md-invalid': validate('password')}">
                                                    <label>{{msg('Password')}}</label>
                                                    <md-input v-on:blur="$v.$touch()" v-model="mail.password"/>
                                                    <span class="md-error" v-if="mail.password!= null &&!$v.mail.password.required">Password is required</span>
                                                </md-field>
                                            </div>
                                        </div>
                                    </div>
                                    <md-divider></md-divider>

                                    <md-subheader>{{msg('Port')}}</md-subheader>
                                    <div class="row ">
                                        <div class="col-1 md-layout-item md-size-4">
                                            <md-list>
                                                <md-list-item>
                                                    <md-icon class="md-primary" style="padding: 0"><i class="fas fa-network-wired"></i></md-icon>
                                                </md-list-item>
                                            </md-list>
                                        </div>
                                        <div class="row col-11">
                                            <div class="md-layout-item mr-2">
                                                <md-field :class="{ 'md-invalid': validate('port')}">
                                                    <label>{{msg('Port')}}</label>
                                                    <md-input v-on:blur="$v.$touch()" v-model="mail.port"/>
                                                    <span class="md-error" v-if="mail.port!= null &&!$v.mail.username.required">Port is required</span>
                                                    <span class="md-error" v-if="mail.port!= null &&!$v.mail.username.numeric">Port should be numeric</span>
                                                </md-field>
                                            </div>

                                            <div  class="md-layout-item mr-2">
                                                <md-field>
                                                    <label>{{msg("Fitter")}}</label>
                                                    <md-select  v-model="mail.userid">
                                                        <md-option value="-1">{{msg("notassign")}}</md-option>
                                                        <md-option v-for="us in getUsersByRole(roleInstalIs)" v-bind:value="us.id" v-bind:key="us.id">
                                                            {{ us.firstname }} {{ us.lastname }}
                                                        </md-option>
                                                    </md-select>
                                                </md-field>
                                            </div>

                                            <div class="md-layout-item">
                                                <md-checkbox v-model="mail.ttls" style="margin-top:30px ">TTLS</md-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                    <md-divider></md-divider>
                                    <md-subheader>{{msg('Signature')}}</md-subheader>

                                    <froala v-model="mail.signature"></froala>
                                </div>
                            </form>
                        </div>
                        <div v-else-if="this.error != null">
                            <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{error}}</strong>
                            </b-alert>
                        </div>
                        <div v-else>
                            <b-spinner label="Spinning"/>
                            {{msg('loading')}}
                        </div>
                    </div>
                </perfect-scrollbar>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

    import {mapActions, mapGetters, mapState} from "vuex";



    import {required} from 'vuelidate/lib/validators'
    import minLength from "vuelidate/src/validators/minLength";
    import numeric from "vuelidate/src/validators/numeric";




    export default {
        name: "smtpSettingsPanel",
        data() {
            return {
                loaded: false,
                error: null,
                mail: {
                    cur: "",
                    id: 0,
                    kycid: 0,
                    mailid: 0,
                    userid: 0,
                    name: "",
                    signature: "",
                    orgid: 1
                },
                //Listeners
                listeners: {submit:()=>{}}
            }
        },
        props: {
            id: Number,
        },
        watch: {
            id: function () { // watch it
                this.onLoad();
                this.error = null;
            }
        },
        computed: {
            ...mapGetters('translator',['msg']),
            ...mapGetters('clients',['roleInstalIs']),
            ...mapGetters('data',['getUsersByRole']),
            ...mapState({
                data: function (store) {
                    return store.data
                },
                settings: function (store) {
                    return store.BranchSettings;
                },

            }),
            getTTls(){
                return this.mail.ttls === true ? "1" : "0";
            }
        },
        created() {

            this.onLoad();
            this.$root.$on('crm::submitSmtpSettings',(this.listeners.submit = () => {
                this.submit();
            }));
        },
        beforeDestroy() {
            this.$root.$off('crm::submitSmtpSettings',this.listeners.submit)
        },
        methods: {
            ...mapActions('BranchSettings', {
                _getMail: 'getMail',
                _updateMail: 'updateMail',
                _register: "registerMail"
            }),
            onLoad() {
                this.loaded = false;
                if (this.id === 0 || typeof this.id !== "number") {
                    this.id = 0;
                    this.mail = {
                        id: 0
                    };
                    this.loaded = true;
                } else
                    this.loadMail();
            },
            loadMail() {
                let $this = this;
                $this.loaded = false;
                this._getMail({id: $this.id}).then((mail) => {
                    $this.mail = mail;
                    //Set page as loaded
                    this.loaded = true;
                }, (error) => {
                    $this.error = error;
                })
            },
            createMail() {
                console.log("updateMail()");
                this._register({mail: this.mail}).then(
                    (a) => this.$emit("submitted", {status: true,id:0, server: a}),
                    (a) => this.$emit("submitted", {status: false, server: a})
                );
                // this.$emit("submited")
            },
            updateMail() {
                console.log("updateMail()");
                this._updateMail({id: this.id, mail: this.mail}).then(
                    (a) => this.$emit("submitted", {status: true, id: a}),
                    (a) => this.$emit("submitted", {status: false, server: a})
                );
                // this.$emit("submited")
            },
            validate(field) {
                try {
                    return  this.$v != null && this.$v.mail != null && this.$v.mail[field] != null && this.$v.mail[field].$error === true;

                }catch (e) {
                    return false;
                }

            },
            submit(e) {

                if (typeof e === 'object' && typeof e.preventDefault === 'function')
                    e.preventDefault();



                console.log(this.$v);
                console.log(this.$v.$invalid);
                if (this.$v.$invalid)
                    return null;

                if (this.id !== 0)
                    return this.updateMail();
                return this.createMail();

            },


        },
        validations: {
            mail: {
                port: {
                    numeric,
                    required,
                },
                username: {
                    required,
                },
                password: {
                    required,
                },
                host: {
                    required,
                },
                sender:{
                    required,
                },
                alias: {
                    required,
                    minLength: minLength(4),
                },
            }
        }
    }
</script>

<style>
    #mailForm .md-list-item-content {
        padding: 0;
    }

    .md-layout-item {
        margin: 0 5px;
    }

    .md-list > .row {
        min-height: 80px;
    }
</style>
